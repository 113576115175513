import React from 'react';
import ApartmentOutlined from '@ant-design/icons/lib/icons/ApartmentOutlined';
import DollarOutlined from '@ant-design/icons/lib/icons/DollarOutlined';
import InboxOutlined from '@ant-design/icons/lib/icons/InboxOutlined';
import ProjectOutlined from '@ant-design/icons/lib/icons/ProjectOutlined';
import ScheduleOutlined from '@ant-design/icons/lib/icons/ScheduleOutlined';
import SendOutlined from '@ant-design/icons/lib/icons/SendOutlined';
import ShopOutlined from '@ant-design/icons/lib/icons/ShopOutlined';
import ShoppingCartOutlined from '@ant-design/icons/lib/icons/ShoppingCartOutlined';
import TagsOutlined from '@ant-design/icons/lib/icons/TagsOutlined';
import ToolOutlined from '@ant-design/icons/lib/icons/ToolOutlined';
import * as queryString from 'query-string';
import { generatePath } from 'react-router-dom';
import { PermissionFunctions } from '../auth/AuthenticationContext';
import ProfileOutlined from '@ant-design/icons/lib/icons/ProfileOutlined';
import moment from 'moment';
import UserInfo from '../models/UserInfo';
import { BookOutlined, VerifiedOutlined } from '@ant-design/icons';
import WikiIDs from '../pages/Wiki/Consts/IDs';

if ($originBackoffice == undefined) {
  throw Error('Backend Not Running');
}

interface ParentMenu {
  Key: string;
  Label: string;
  Icon: JSX.Element;
}

interface MenuGroup {
  Label: string;
}

interface PageInfoProps {
  Path: string;
  MenuKey: string;
  WikiId: string;
  ParentMenu?: ParentMenu;
  PermissionFunction: string;
  StartPage?: boolean;
  StartPagePriority?: number;
  MenuDebugOnly?: boolean;
  MenuLabel?: string;
  MenuIcon?: JSX.Element;
  MenuGroup?: MenuGroup;
}

export class PageInfo {
  public Path: string; //value to use for 'path' prop on Route element in App.tsx. More specific routes for the same page should be first in RoutesList (purchasing/list/:ponum before purchasing/list so GetCurrentRouteFromLocation works)
  public MenuKey: string; //key of side menu item to highlight when this route is being used. should match MenuKey of whatever route has MenuLabel = the menu item we want to highlight. also used when dynamically generating the menu.
  public WikiId: string; //id from WikiIDs. use the same WikiID for multiple routes to the same page
  //if route has MenuLabel set:
  //a) add id variable to WikiIDs
  //b) assign the id to this field
  //a wiki for the page will be dynamically created based on the menu name
  //if route does not have MenuLabel set:
  //a) add id variable to WikiIDs
  //b) assign the id to this field
  //c) add a new wiki for the id (see wiki -> details.tsx, for components in wiki 'PageDocs' folder)
  public ParentMenu?: ParentMenu; //parent menu information if the menu item corrisponding to MenuKey is a submenu.
  public PermissionFunction: string; //canUserAccessPermission(PermissionFunc) must be true for the page to be accessable, and menu item visible (if applicable).
  public StartPage?: boolean; //if set, the page can potentially be the start page for the user based on their permissions. no effect if route path has parameters.
  public StartPagePriority?: number; //for user start page, prioritize routes with lower value over ones with higher or no value.
  public MenuDebugOnly?: boolean; //if true, the menu item associated with the route will only display when running locally.
  public MenuLabel?: string; //if set, a side menu item will be created based on this route. Menu items will be added in the order they're in in the RoutesList variable. There should only be one route with this set per distinct MenuKey value.
  public MenuIcon?: JSX.Element; //icon to display in side menu item
  public MenuGroup?: MenuGroup; //group information if the menu item corrisponding to MenuKey is a submenu and in a group.

  constructor(props: PageInfoProps) {
    this.Path = props.Path;
    this.MenuKey = props.MenuKey;
    this.WikiId = props.WikiId;
    this.ParentMenu = props.ParentMenu;
    this.PermissionFunction = props.PermissionFunction;
    this.StartPage = props.StartPage;
    this.StartPagePriority = props.StartPagePriority;
    this.MenuDebugOnly = props.MenuDebugOnly;
    this.MenuLabel = props.MenuLabel;
    this.MenuIcon = props.MenuIcon;
    this.MenuGroup = props.MenuGroup;
  }
}

class Routes {
  //#region private variables
  private static wiki_menu: ParentMenu = {
    Key: 'WIKIMENU',
    Label: 'Documentation',
    Icon: <BookOutlined />,
  };
  public static wiki_clicksearch_key = 'WIKICLICKSEARCH';
  public static wiki_list_menulabel = 'List';
  private static wiki_key = 'WIKI';
  private static vendor_menu = 'VENDORS';
  private static brands_menu = 'BRANDS';
  private static supplychain_menu = 'SUPPLYCHAIN';
  private static supplychainlist_path = '/SupplyChain/list';
  private static supplychaindetail_path = '/SupplyChain/details';
  private static pricingordering_menu: ParentMenu = {
    Key: 'PRICINGORDERING',
    Label: 'Pricing / Ordering',
    Icon: <DollarOutlined />,
  };
  private static checkpricing_submenu = 'CHECKPRICING';
  private static inventory_menu: ParentMenu = { Key: 'INVENTORY', Label: 'Inventory', Icon: <InboxOutlined /> };
  private static purchasing_submenu = 'PURCHASING';
  private static production_menu: ParentMenu = { Key: 'PRODUCTION', Label: 'Production', Icon: <ProjectOutlined /> };
  private static quality_menu: ParentMenu = { Key: 'QUALITY', Label: 'Quality', Icon: <VerifiedOutlined /> };
  private static sanitation_facilities_menu: ParentMenu = {
    Key: 'SANITATION_FACILITIES',
    Label: 'Sanitation & Facilities',
    Icon: <ShopOutlined />,
  };
  private static maintenance_menu: ParentMenu = { Key: 'MAINTENANCE', Label: 'Maintenance', Icon: <ToolOutlined /> };
  private static monitoring_verification_menu: ParentMenu = {
    Key: 'MONITORING_VERIFICATION',
    Label: 'Monitoring & Verification',
    Icon: <ScheduleOutlined />,
  };
  private static fulfillment_menu: ParentMenu = { Key: 'FULFILLMENT', Label: 'Fulfillment', Icon: <SendOutlined /> };
  private static reports_menu = 'REPORTS';
  private static stockyards_group: MenuGroup = { Label: 'Stockyards' };
  private static easton_group: MenuGroup = { Label: 'Easton' };
  private static production_corporate_group: MenuGroup = { Label: 'Corporate' };
  private static maintenance_corporate_group: MenuGroup = { Label: 'Corporate' };

  //#endregion

  public static RoutesList = {
    HOME: new PageInfo({ Path: '/', MenuKey: '', WikiId: '', PermissionFunction: '' }),
    SYSTEMALERTS: new PageInfo({
      Path: '/SystemAlert/list',
      MenuKey: '',
      WikiId: WikiIDs.id_sysal,
      PermissionFunction: '',
    }),
    CCARD_RETRY: new PageInfo({
      Path: '/CreditCardRetry/:transactionId',
      MenuKey: '',
      WikiId: '',
      PermissionFunction: '',
    }),
    SALESORDERS_LIST: new PageInfo({
      Path: '/SalesOrders/List',
      MenuKey: 'SALESORDERS_EDIT',
      WikiId: '',
      PermissionFunction: PermissionFunctions.SALES_ORDER_LIST,
      MenuDebugOnly: true,
      MenuLabel: 'Order List/Edit',
    }),
    SALESORDERS_EDIT_STANDALONE: new PageInfo({
      //DO NOT CHANGE WITHOUT NOTIFYING THE DEVELOPER AT BIOZYME
      //WHO'S RESPONSIBLE FOR BACKOFFICE! (Nick Reed at time of writing)
      //This route is used to display our app in an iframe on their site.
      Path: '/SalesOrders/EditStandalone',
      MenuKey: '',
      WikiId: '',
      PermissionFunction: '',
    }),
    SALESORDERS_EDIT: new PageInfo({
      Path: '/SalesOrders/Edit/:sopNumber',
      MenuKey: 'SALESORDERS_EDIT',
      WikiId: '',
      PermissionFunction: '',
    }),
    SALESORDERS_CREATE_STANDALONE: new PageInfo({
      //DO NOT CHANGE WITHOUT NOTIFYING THE DEVELOPER AT BIOZYME
      //WHO'S RESPONSIBLE FOR BACKOFFICE! (Nick Reed at time of writing)
      //This route is used to display our app in an iframe on their site.
      Path: '/SalesOrders/CreateStandalone',
      MenuKey: '',
      WikiId: '',
      PermissionFunction: '',
    }),
    SALESORDERS_CREATE: new PageInfo({
      Path: '/SalesOrders/Create',
      MenuKey: 'SALESORDERS_CREATE',
      WikiId: '',
      PermissionFunction: '',
      MenuDebugOnly: true,
      MenuLabel: 'Order Create',
    }),
    WIKICLICKSEARCH: new PageInfo({
      Path: '/wikiclicksearch',
      MenuKey: Routes.wiki_clicksearch_key,
      WikiId: WikiIDs.id_wiki,
      ParentMenu: this.wiki_menu,
      PermissionFunction: '',
      MenuLabel: 'Search What You Click',
    }),
    WIKI_LIST: new PageInfo({
      Path: '/wiki/list',
      MenuKey: this.wiki_key,
      WikiId: WikiIDs.id_wiki,
      ParentMenu: this.wiki_menu,
      PermissionFunction: '',
      MenuLabel: this.wiki_list_menulabel,
    }),
    WIKI_DETAIL: new PageInfo({
      Path: '/wiki/detail/:id',
      MenuKey: this.wiki_key,
      WikiId: WikiIDs.id_wiki,
      ParentMenu: this.wiki_menu,
      PermissionFunction: '',
    }),
    VENDORS: new PageInfo({
      Path: '/Vendors/List',
      MenuKey: this.vendor_menu,
      WikiId: WikiIDs.id_vendors,
      PermissionFunction: PermissionFunctions.VENDORS,
      StartPage: true,
      MenuLabel: 'Vendors',
      MenuIcon: <ShoppingCartOutlined />,
    }),
    VENDORS_Detail: new PageInfo({
      Path: '/Vendors/Detail/:id',
      MenuKey: this.vendor_menu,
      WikiId: WikiIDs.id_vendordetail,
      PermissionFunction: PermissionFunctions.VENDORS,
    }),
    VENDORS_Request: new PageInfo({
      Path: '/Vendors/Request/:id',
      MenuKey: this.vendor_menu,
      WikiId: WikiIDs.id_vendorrequest,
      PermissionFunction: PermissionFunctions.VENDORS_REQUEST,
    }),
    BRANDS: new PageInfo({
      Path: '/brands/list',
      MenuKey: this.brands_menu,
      WikiId: WikiIDs.id_brandpage,
      PermissionFunction: PermissionFunctions.BRANDS,
      StartPage: true,
      MenuLabel: 'Brands',
      MenuIcon: <TagsOutlined />,
    }),
    BRANDS_DETAIL: new PageInfo({
      Path: '/brands/detail/:brandId',
      MenuKey: this.brands_menu,
      WikiId: WikiIDs.id_brandpage,
      PermissionFunction: PermissionFunctions.BRANDS,
    }),
    SUPPLYCHAIN: new PageInfo({
      Path: this.supplychainlist_path,
      MenuKey: this.supplychain_menu,
      WikiId: WikiIDs.id_splychn,
      PermissionFunction: PermissionFunctions.SUPPLYCHAIN,
      StartPage: true,
      StartPagePriority: 1,
      MenuLabel: 'Supply Chain',
      MenuIcon: <ApartmentOutlined />,
    }),
    SUPPLYCHAIN_FromSystemAlert: new PageInfo({
      Path: this.supplychainlist_path + '/:type',
      MenuKey: this.supplychain_menu,
      WikiId: WikiIDs.id_splychn,
      PermissionFunction: PermissionFunctions.SUPPLYCHAIN,
    }),
    SUPPLYCHAIN_Detail_File: new PageInfo({
      Path: this.supplychaindetail_path + '/:itemNumber/fi/:lotNumber/:fileType/',
      MenuKey: this.supplychain_menu,
      WikiId: WikiIDs.id_splychndet,
      PermissionFunction: PermissionFunctions.SUPPLYCHAIN,
    }),
    SUPPLYCHAIN_Detail_Formula: new PageInfo({
      Path: this.supplychaindetail_path + '/:itemNumber/frmh/:formulaType/:formulaId/',
      MenuKey: this.supplychain_menu,
      WikiId: WikiIDs.id_splychndet,
      PermissionFunction: PermissionFunctions.SUPPLYCHAIN,
    }),
    SUPPLYCHAIN_Detail_HazardAnalysis: new PageInfo({
      Path: this.supplychaindetail_path + '/:itemNumber/hza/:hazardAnalysisId/',
      MenuKey: this.supplychain_menu,
      WikiId: WikiIDs.id_splychndet,
      PermissionFunction: PermissionFunctions.SUPPLYCHAIN,
    }),
    SUPPLYCHAIN_Detail: new PageInfo({
      Path: this.supplychaindetail_path + '/:itemNumber/:tab?/',
      MenuKey: this.supplychain_menu,
      WikiId: WikiIDs.id_splychndet,
      PermissionFunction: PermissionFunctions.SUPPLYCHAIN,
    }),
    SUPPLYCHAIN_RequestDetail: new PageInfo({
      Path: '/SupplyChain/requestDetails/:id',
      MenuKey: this.supplychain_menu,
      WikiId: WikiIDs.id_itemrequest,
      PermissionFunction: PermissionFunctions.SUPPLYCHAIN_Request,
    }),
    CUSTOMPRICING: new PageInfo({
      Path: '/custompricing/list',
      MenuKey: 'CUSTOMPRICING',
      WikiId: WikiIDs.id_custprc,
      ParentMenu: this.pricingordering_menu,
      PermissionFunction: PermissionFunctions.PRICING,
      StartPage: true,
      MenuLabel: 'Custom Pricing',
    }),
    PRODUCT_TYPES: new PageInfo({
      Path: '/ProductTypes/list',
      MenuKey: 'PRODUCT_TYPES',
      WikiId: WikiIDs.id_ptpage,
      ParentMenu: this.pricingordering_menu,
      PermissionFunction: PermissionFunctions.PRICING,
      StartPage: true,
      MenuLabel: 'Product Types',
    }),
    CHECKPRICING: new PageInfo({
      Path: '/pricing/util',
      MenuKey: this.checkpricing_submenu,
      WikiId: WikiIDs.id_checkpricing,
      ParentMenu: this.pricingordering_menu,
      PermissionFunction: PermissionFunctions.PRICING,
      StartPage: true,
      MenuLabel: 'Check Pricing',
    }),
    PROMOTIONS: new PageInfo({
      Path: '/promotions/list',
      MenuKey: 'PROMOTIONS',
      WikiId: WikiIDs.id_promo,
      ParentMenu: this.pricingordering_menu,
      PermissionFunction: PermissionFunctions.PRICING,
      StartPage: true,
      MenuLabel: 'Promotions',
    }),
    DISTRIBUTIONS: new PageInfo({
      Path: '/distributions/list',
      MenuKey: 'DISTRIBUTIONS',
      WikiId: WikiIDs.id_dist,
      ParentMenu: this.pricingordering_menu,
      PermissionFunction: PermissionFunctions.PRICING,
      StartPage: true,
      MenuLabel: 'Distributions',
    }),
    BIOZYMEBUCKS: new PageInfo({
      Path: '/BioZymeBucks/list',
      MenuKey: 'BIOZYMEBUCKS',
      WikiId: WikiIDs.id_biozymebucks,
      ParentMenu: this.pricingordering_menu,
      PermissionFunction: PermissionFunctions.BIOZYMEBUCKS,
      StartPage: true,
      MenuLabel: 'BioZyme Bucks',
    }),
    FORECASTING: new PageInfo({
      Path: '/forecasting/list',
      MenuKey: 'FORECASTING',
      WikiId: WikiIDs.id_frc,
      ParentMenu: this.inventory_menu,
      PermissionFunction: PermissionFunctions.FORECASTING,
      StartPage: true,
      MenuLabel: 'Forecasting',
    }),
    PURCHASING_PONUM: new PageInfo({
      Path: '/purchasing/list/:ponum',
      MenuKey: this.purchasing_submenu,
      WikiId: WikiIDs.id_purch,
      ParentMenu: this.inventory_menu,
      PermissionFunction: PermissionFunctions.PURCHASING,
    }),
    PURCHASING: new PageInfo({
      Path: '/purchasing/list',
      MenuKey: this.purchasing_submenu,
      WikiId: WikiIDs.id_purch,
      ParentMenu: this.inventory_menu,
      PermissionFunction: PermissionFunctions.PURCHASING,
      StartPage: true,
      StartPagePriority: 2,
      MenuLabel: 'Purchasing',
    }),
    INVENTORY_CHECKLISTS: new PageInfo({
      Path: '/inventory/checklists',
      MenuKey: 'INVENTORY_CHECKLISTS',
      WikiId: WikiIDs.id_invchecklist,
      ParentMenu: this.inventory_menu,
      PermissionFunction: PermissionFunctions.INVENTORY_CHECKLIST,
      StartPage: true,
      MenuLabel: 'Checklists',
    }),
    PRODUCTION_AMAFERM_DRYING: new PageInfo({
      Path: '/production/stockyards/amaferm-drying',
      MenuKey: 'PRODUCTION_AMAFERM_DRYING',
      WikiId: WikiIDs.id_amdry,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.AMAFERM_DRYING,
      StartPage: true,
      MenuLabel: 'Amaferm Drying',
    }),
    PRODUCTION_AO_REQUEST_DETAIL: new PageInfo({
      Path: '/production/stockyards/ao-request/:id',
      MenuKey: 'PRODUCTION_AO_REQUEST',
      WikiId: WikiIDs.id_amreq,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.AOREQUEST,
    }),
    PRODUCTION_AO_REQUEST: new PageInfo({
      Path: '/production/stockyards/ao-request',
      MenuKey: 'PRODUCTION_AO_REQUEST',
      WikiId: WikiIDs.id_amreq,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.AOREQUEST,
      StartPage: true,
      MenuLabel: 'AO Requests',
    }),
    MIXINGSCHEDULER: new PageInfo({
      Path: '/Scheduling/List',
      MenuKey: 'MIXINGSCHEDULER',
      WikiId: WikiIDs.id_mixingsch,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.MIXINGSCHEDULING,
      StartPage: true,
      MenuLabel: 'Mixing Scheduler',
    }),
    PRODUCTION_MIXING: new PageInfo({
      Path: '/production/stockyards/mixing',
      MenuKey: 'PRODUCTION_MIXING',
      WikiId: WikiIDs.id_mixing,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.MIXING,
      StartPage: true,
      MenuLabel: 'Mixing',
    }),
    PRODUCTION_BAGGING: new PageInfo({
      Path: '/production/stockyards/bagging',
      MenuKey: 'PRODUCTION_BAGGING',
      WikiId: WikiIDs.id_bagging,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.BAGGING,
      StartPage: true,
      MenuLabel: 'Bagging',
    }),
    REWORKS: new PageInfo({
      Path: '/reworks/list',
      MenuKey: 'REWORKS',
      WikiId: WikiIDs.id_reworks,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.REWORKS,
      StartPage: true,
      MenuLabel: 'Reworks',
    }),
    PRODUCTION_TestingLab: new PageInfo({
      Path: '/production/stockyards/TestingLab',
      MenuKey: 'PRODUCTION_TestingLab',
      WikiId: WikiIDs.id_stockyardtestinglab,
      ParentMenu: this.production_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.TestingLab,
      StartPage: true,
      MenuLabel: 'Testing Lab',
    }),
    PRODUCTION_SEEDLAB_EASTON: new PageInfo({
      Path: '/production/easton/seedlab',
      MenuKey: 'PRODUCTION_SEEDLAB_EASTON',
      WikiId: WikiIDs.id_eastonsdlb,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.SEEDLAB,
      StartPage: true,
      MenuLabel: 'Seed Lab',
    }),
    PRODUCTION_MEDIAMIXING_EASTON: new PageInfo({
      Path: '/production/easton/mediamixing',
      MenuKey: 'PRODUCTION_MEDIAMIXING_EASTON',
      WikiId: WikiIDs.id_eastonmdmx,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.MEDIAMIXING,
      StartPage: true,
      MenuLabel: 'Media Mixing',
    }),
    PRODUCTION_FERMENTATION_EASTON: new PageInfo({
      Path: '/production/easton/fermentation',
      MenuKey: 'PRODUCTION_FERMENTATION_EASTON',
      WikiId: WikiIDs.id_eastonferm,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.FERMENTATION,
      StartPage: true,
      MenuLabel: 'Fermentation',
    }),
    PRODUCTION_STORAGE_EASTON: new PageInfo({
      Path: '/production/easton/storage',
      MenuKey: 'PRODUCTION_STORAGE_EASTON',
      WikiId: WikiIDs.id_eastonstorage,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.STORAGE,
      StartPage: true,
      MenuLabel: 'Storage',
    }),
    PRODUCTION_CHECKLISTS_EASTON: new PageInfo({
      Path: '/production/easton/checklists',
      MenuKey: 'PRODUCTION_CHECKLISTS_EASTON',
      WikiId: WikiIDs.id_eastonchecklist,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: '',
      StartPage: true,
      MenuLabel: 'Checklists',
    }),
    PRODUCTION_EkonekDryer_EASTON: new PageInfo({
      Path: '/production/easton/EkonekDryer',
      MenuKey: 'PRODUCTION_EkonekDryer_EASTON',
      WikiId: WikiIDs.id_eastonekonek,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.EKONEK_DRYER,
      StartPage: true,
      MenuLabel: 'Ekonek Dryer',
    }),
    PRODUCTION_PtBagger_EASTON: new PageInfo({
      Path: '/production/easton/PtBagger',
      MenuKey: 'PRODUCTION_PtBagger_EASTON',
      WikiId: WikiIDs.id_eastonptbag,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.PT_BAGGING,
      StartPage: true,
      MenuLabel: 'PT Bagger',
    }),
    PRODUCTION_Scheduling_EASTON: new PageInfo({
      Path: '/production/easton/Scheduling',
      MenuKey: 'PRODUCTION_Scheduling_EASTON',
      WikiId: WikiIDs.id_eastonsch,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: '',
      MenuLabel: 'Scheduling',
    }),
    PRODUCTION_SetupFromPlanning_CORPORATE: new PageInfo({
      Path: '/production/setup/:resourceType',
      MenuKey: 'PRODUCTION_Setup_CORPORATE',
      WikiId: WikiIDs.id_productionsetup,
      ParentMenu: this.production_menu,
      MenuGroup: this.maintenance_corporate_group,
      PermissionFunction: PermissionFunctions.EASTON_SCHEDULING,
    }),
    PRODUCTION_Setup_CORPORATE: new PageInfo({
      Path: '/production/setup',
      MenuKey: 'PRODUCTION_Setup_CORPORATE',
      WikiId: WikiIDs.id_productionsetup,
      ParentMenu: this.production_menu,
      MenuGroup: this.maintenance_corporate_group,
      PermissionFunction: PermissionFunctions.EASTON_SCHEDULING,
      MenuLabel: 'Setup',
    }),
    PRODUCTION_LAB_EASTON: new PageInfo({
      Path: '/production/easton/lab',
      MenuKey: 'PRODUCTION_lab_EASTON',
      WikiId: WikiIDs.id_eastonlab,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.LAB_EASTON_CHECKLISTS,
      StartPage: true,
      MenuLabel: 'Lab',
    }),
    PRODUCTION_ANALYTICAL_LAB_EASTON: new PageInfo({
      Path: '/production/easton/analytical-lab',
      MenuKey: 'PRODUCTION_LAB_ANALYTICALEASTON',
      WikiId: WikiIDs.id_eastonanalyticallab,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.LAB_EASTON_CHECKLISTS,
      StartPage: true,
      MenuLabel: 'Analytical Lab',
    }),
    QUALITY_STOCKYARDS: new PageInfo({
      Path: '/quality/stockyards',
      MenuKey: 'QUALITY_STOCKYARDS',
      WikiId: WikiIDs.id_stockyardsqly,
      ParentMenu: this.quality_menu,
      PermissionFunction: PermissionFunctions.QUALITY_STOCKYARDS_CHECKLISTS,
      StartPage: true,
      MenuLabel: 'Stockyards',
    }),
    QUALITY_EASTON: new PageInfo({
      Path: '/quality/easton',
      MenuKey: 'QUALITY_EASTON',
      WikiId: WikiIDs.id_eastonqly,
      ParentMenu: this.quality_menu,
      PermissionFunction: PermissionFunctions.QUALITY_EASTON_CHECKLISTS,
      StartPage: true,
      MenuLabel: 'Easton',
    }),
    SANITATION_STOCKYARDS: new PageInfo({
      Path: '/sanitation/stockyards',
      MenuKey: 'SANITATION_STOCKYARDS',
      WikiId: WikiIDs.id_stockyardsan,
      ParentMenu: this.sanitation_facilities_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.SANITATION,
      StartPage: true,
      MenuLabel: 'Sanitation',
    }),
    FACILITIES_STOCKYARDS: new PageInfo({
      Path: '/facilities/stockyards',
      MenuKey: 'FACILITIES_STOCKYARDS',
      WikiId: WikiIDs.id_stockyardfac,
      ParentMenu: this.sanitation_facilities_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.FACILITIES,
      StartPage: true,
      MenuLabel: 'Facilities',
    }),
    SANITATION_EASTON: new PageInfo({
      Path: '/sanitation/easton',
      MenuKey: 'SANITATION_EASTON',
      WikiId: WikiIDs.id_eastonsan,
      ParentMenu: this.sanitation_facilities_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.SANITATION_EASTON,
      StartPage: true,
      MenuLabel: 'Sanitation',
    }),
    FACILITIES_EASTON: new PageInfo({
      Path: '/facilities/easton',
      MenuKey: 'FACILITIES_EASTON',
      WikiId: WikiIDs.id_eastonfac,
      ParentMenu: this.sanitation_facilities_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.FACILITIES_EASTON,
      StartPage: true,
      MenuLabel: 'Facilities',
    }),
    MAINTENANCE_CHECKLISTS_STOCKYARDS: new PageInfo({
      Path: '/maintenance/checklists/stockyards',
      MenuKey: 'MAINTENANCE_CHECKLISTS_STOCKYARDS',
      WikiId: WikiIDs.id_stockyardmaincheck,
      ParentMenu: this.maintenance_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.MAINTENANCE_CHECKLIST,
      StartPage: true,
      MenuLabel: 'Checklists',
    }),
    MAINTENANCE_CHECKLISTS_EASTON: new PageInfo({
      Path: '/maintenance/checklists/easton',
      MenuKey: 'MAINTENANCE_CHECKLISTS_EASTON',
      WikiId: WikiIDs.id_eastonmaincheck,
      ParentMenu: this.maintenance_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.MAINTENANCE_CHECKLIST_EASTON,
      StartPage: true,
      MenuLabel: 'Checklists',
    }),
    TRACKING_VERIFICATION_STOCKYARDS: new PageInfo({
      Path: '/tracking/verification/stockyards',
      MenuKey: 'TRACKING_VERIFICATION_STOCKYARDS',
      WikiId: WikiIDs.id_stockyardtrackver,
      ParentMenu: this.monitoring_verification_menu,
      MenuGroup: this.stockyards_group,
      PermissionFunction: PermissionFunctions.VERIFICATION,
      StartPage: true,
      MenuLabel: 'Verification',
    }),
    TRACKING_MEDIAMIXING_EASTON: new PageInfo({
      Path: '/tracking/easton/mediamixingverification',
      MenuKey: 'TRACKING_MEDIAMIXING_EASTON',
      WikiId: WikiIDs.id_eastonmdmxver,
      ParentMenu: this.monitoring_verification_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.MEDIAMIXING_VERIFICATION,
      StartPage: true,
      MenuLabel: 'Media Mixing Verification',
    }),
    TRACKING_FERMENTATION_EASTON: new PageInfo({
      Path: '/tracking/easton/fermentationverification',
      MenuKey: 'TRACKING_FERMENTATION_EASTON',
      WikiId: WikiIDs.id_eastonfermver,
      ParentMenu: this.monitoring_verification_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.FERMENTATION_VERIFICATION,
      StartPage: true,
      MenuLabel: 'Fermentation Verification',
    }),
    TRACKING_STORAGE_EASTON: new PageInfo({
      Path: '/tracking/easton/storageverification',
      MenuKey: 'TRACKING_STORAGE_EASTON',
      WikiId: WikiIDs.id_eastonstorver,
      ParentMenu: this.monitoring_verification_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.FERMENTATION_VERIFICATION,
      StartPage: true,
      MenuLabel: 'Storage Verification',
    }),

    PRODUCTION_LAB_EASTON_VERIFICATION: new PageInfo({
      Path: '/production/easton/lab-verification',
      MenuKey: 'PRODUCTION_lab_EASTON_VERIFICATION',
      WikiId: WikiIDs.id_eastonlabver,
      ParentMenu: this.monitoring_verification_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: PermissionFunctions.FERMENTATION_VERIFICATION,
      StartPage: true,
      MenuLabel: 'Lab Verification',
    }),
    DOWNSTREAM: new PageInfo({
      Path: '/production/easton/downstream',
      MenuKey: 'DOWNSTREAM',
      WikiId: WikiIDs.id_downstream,
      ParentMenu: this.production_menu,
      MenuGroup: this.easton_group,
      PermissionFunction: '',
      StartPage: true,
      MenuLabel: 'Downstream',
    }),
    TRACKING_TRACKINGSETUP_CORPORATE: new PageInfo({
      Path: '/tracking/setup',
      MenuKey: 'TRACKING_TRACKINGSETUP_CORPORATE',
      WikiId: WikiIDs.id_trkset,
      ParentMenu: this.monitoring_verification_menu,
      MenuGroup: this.maintenance_corporate_group,
      PermissionFunction: PermissionFunctions.TRACKINGSETUP,
      StartPage: true,
      MenuLabel: 'Tracking Setup',
    }),
    FREIGHT: new PageInfo({
      Path: '/fulfillment/freight',
      MenuKey: 'FREIGHT',
      WikiId: WikiIDs.id_freight,
      ParentMenu: this.fulfillment_menu,
      PermissionFunction: PermissionFunctions.FREIGHT,
      StartPage: true,
      MenuLabel: 'Freight',
    }),
    TIMES: new PageInfo({
      Path: '/fulfillment/times',
      MenuKey: 'TIMES',
      WikiId: WikiIDs.id_times,
      ParentMenu: this.fulfillment_menu,
      PermissionFunction: PermissionFunctions.SHIPPINGTIME,
      StartPage: true,
      MenuLabel: 'Time',
    }),
    REPORTS_REPORT: new PageInfo({
      Path: '/reports/list/:reportName',
      MenuKey: this.reports_menu,
      WikiId: WikiIDs.id_reports,
      PermissionFunction: PermissionFunctions.REPORTS,
    }),
    REPORTS_LIST: new PageInfo({
      Path: '/reports/list',
      MenuKey: this.reports_menu,
      WikiId: WikiIDs.id_reports,
      PermissionFunction: PermissionFunctions.REPORTS,
      StartPage: true,
      MenuLabel: 'Reports',
      MenuIcon: <ProfileOutlined />,
    }),
  };

  public static getCurrentRouteFromLocation(pathname: string) {
    const locationParts = this.pathWithNoEndingSlash(pathname).split('/');
    const curRoute = Object.values(this.RoutesList).find((route) => {
      return this.pathWithNoEndingSlash(route.Path)
        .split('/')
        .every((part, index) => {
          return (
            part.endsWith('?') ||
            (locationParts.length > index &&
              (part.startsWith(':') || part.toLowerCase() == locationParts[index].toLowerCase()))
          );
        });
    });

    return curRoute || this.RoutesList.HOME;
  }

  private static pathWithNoEndingSlash(path: string) {
    return path !== '/' && path.endsWith('/') ? path.slice(0, -1) : path;
  }

  /**
   * Generated a url from a route and parameters.
   * @param route Route that may contain parameter placeholders.
   * @param params Object where property names equal the parameter placeholders in the route an the property value is what will be injected.
   */
  public static generate(route?: string, params?: { [paramName: string]: string }, query?: { [name: string]: any }) {
    if (route) {
      let path = generatePath(route, params);

      // Add any query string variables to the route if passed
      if (query) {
        const q = queryString.stringify(query);
        if (q) {
          path += `?${q}`;
        }
      }

      return path;
    } else {
      return '';
    }
  }

  public static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true; //cross origin restriction will cause an exception, so if there is one we're in an iframe on a site with a different origin.
    }
  }

  public static redirectIfTokenExpired(userInfo: UserInfo | undefined) {
    if (!$isRunningLocally && userInfo && moment().isAfter(userInfo.tokenExpiration)) {
      //check if token has expired
      if (Routes.inIframe()) {
        //don't want to go to the login page within the iframe. change parent url to login page
        //of whatever site the iframe is open through. user will have to navigate back to the iframe page
        //themselves after logging back in since there isn't currently a way to resubmit the data needed
        //to get to the order form iframe page.
        window.parent.location = userInfo.tokenOrigin + '/login';
      } else {
        //url will go to backoffice login page, then redirect back to the current page on
        //successful login.
        const pathName = location.pathname[0] === '/' ? location.pathname.substring(1) : location.pathname;
        window.location.href = $externalUrlPrefix + pathName;
      }
    }
  }

  public static getPermissionsThatAllowFunction = (func: string): string[] => {
    switch (func) {
      case PermissionFunctions.BIOZYMEBUCKS:
        return ['biozymebucks'];
      case PermissionFunctions.SALESORDEREDIT_Delete:
        return ['salesorderedit_delete'];
      case PermissionFunctions.MAINTENANCE_CHECKLIST:
        return ['maintenance_checklists', 'scheduling_manchange'];
      case PermissionFunctions.MAINTENANCE_CHECKLIST_EASTON:
        return ['maintenance_checklists_easton', 'scheduling_manchange'];
      case PermissionFunctions.SANITATION:
        return ['sanitation', 'scheduling_manchange'];
      case PermissionFunctions.SANITATION_EASTON:
        return ['sanitation_easton', 'scheduling_manchange'];
      case PermissionFunctions.FACILITIES:
        return ['facilities', 'scheduling_manchange'];
      case PermissionFunctions.FACILITIES_EASTON:
        return ['facilities_easton', 'scheduling_manchange'];
      case PermissionFunctions.MIXING:
        return ['mixing', 'scheduling_manchange'];
      case PermissionFunctions.AOREQUEST:
      case PermissionFunctions.AMAFERM_DRYING:
        return ['amafermdrying', 'amafermdrying_schedule', 'scheduling_manchange'];
      //ao request: team notification links will only work if they have access to amaferm drying. if we need to allow access to people
      //on the notification who don't have access to amaferm drying:
      //-adding team notification check to getuserinfo adds 4 seconds on to loading time, db object is initializing or something since this
      //is the first query being executed in the app. we don't want this additional loading time, since this endpoint is called every time
      //the app is opened
      //-partial fix: have notification link to a seperate page that does the permission and team notification check, then links to the ao request
      //page if successful. this would work for visiting the request from the email, but not if they want to see the ao request page by visiting
      //the app through backoffice.
      case PermissionFunctions.INVENTORY_CHECKLIST:
        return ['inventory', 'scheduling_manchange'];
      case PermissionFunctions.SHIPPINGTIME:
        return ['shippingtime'];
      case PermissionFunctions.BRANDS:
        return ['brands'];
      case PermissionFunctions.FORECASTING:
        return ['forecasting'];
      case PermissionFunctions.FORECASTING_WRITE:
        return ['forecasting_write'];
      case PermissionFunctions.PRODUCTTYPESWrite:
        return ['pricingordering_write'];
      case PermissionFunctions.PRICING:
        return ['pricingordering'];
      case PermissionFunctions.REPORTS:
        return [
          'reports_gp',
          'reports_pricelists',
          'reports_orders',
          'reports_supplychain',
          'reports_manufacturing',
          'reports_quality',
        ];
      case PermissionFunctions.PURCHASING:
        return ['purchasing', 'purchasing_production'];
      case PermissionFunctions.PURCHASINGWRITE:
        return ['purchasing_write'];
      case PermissionFunctions.TRACKINGSETUP:
        return ['tracking'];
      case PermissionFunctions.TRACKINGTEAMNOTIFICATIONS:
        return ['tracking_teamnotifications'];
      case PermissionFunctions.TRACKINGQUICKCOMPLETE:
        return ['tracking_quick_complete'];
      case PermissionFunctions.MIXINGSCHEDULING:
        return ['scheduling_manchange'];
      case PermissionFunctions.SCHEDULINGMANCHANGE:
        return ['scheduling_manchange'];
      case PermissionFunctions.REWORKS:
        return ['reworks'];
      case PermissionFunctions.VENDORS:
        return ['vendors'];
      case PermissionFunctions.VENDORS_SPENDING:
        return ['vendors_hidespending'];
      case PermissionFunctions.SUPPLYCHAIN:
        return ['supplychain'];
      case PermissionFunctions.SUPPLYCHAIN_Write:
        return ['supplychain_write'];
      case PermissionFunctions.SUPPLYCHAIN_Request:
        return ['supplychain_request'];
      case PermissionFunctions.SUPPLYCHAIN_Description:
        return ['supplychain_description'];
      case PermissionFunctions.SUPPLYCHAIN_Files:
        return ['supplychain_files'];
      case PermissionFunctions.SUPPLYCHAIN_PricingDetails:
        return ['supplychain_pricingdetail'];
      case PermissionFunctions.PURCHASING_ITEMCOST:
        return ['purchasing_itemcost'];
      case PermissionFunctions.SALESORDERManager:
        return ['salesorder_manager'];
      case PermissionFunctions.SALESORDEROverrideMin:
        return ['salesordercreate_overridemin'];
      case PermissionFunctions.SEEDLAB:
        return ['seed_lab', 'fermentation', 'scheduling_manchange'];
      case PermissionFunctions.VERIFICATION_FINAL_PRODUCT:
        return ['verification_final_product'];
      case PermissionFunctions.MEDIAMIXING:
        return ['media_mixing', 'fermentation', 'scheduling_manchange'];
      case PermissionFunctions.FERMENTATION:
        return ['fermentation', 'scheduling_manchange'];
      case PermissionFunctions.MEDIAMIXING_VERIFICATION:
        return ['verification_fermentation', 'scheduling_manchange'];
      case PermissionFunctions.FERMENTATION_VERIFICATION:
        return ['verification_fermentation', 'scheduling_manchange', 'verification_media_mixing'];
      case PermissionFunctions.VERIFICATION:
        return [
          'verification_bagging',
          'verification_final_product',
          'verification_small_pack_bag_check',
          'scheduling_manchange',
        ];
      case PermissionFunctions.FREIGHT:
        return ['freight'];
      case PermissionFunctions.FREIGHT_WRITE:
        return ['freight_write'];
      case PermissionFunctions.VENDORS_REQUEST:
        return ['vendors_request'];
      case PermissionFunctions.EKONEK_DRYER:
        return ['ekonek_dryer', 'scheduling_manchange'];
      case PermissionFunctions.PT_BAGGING:
        return ['pt_bagging', 'scheduling_manchange'];
      case PermissionFunctions.TestingLab:
        return ['testing_lab', 'scheduling_manchange'];
      case PermissionFunctions.LAB_EASTON_CHECKLISTS:
        return ['lab_easton_checklists', 'scheduling_manchange'];
      case PermissionFunctions.STORAGE:
        return ['fermentation', 'scheduling_manchange'];
      case PermissionFunctions.QUALITY_STOCKYARDS_CHECKLISTS:
        return ['quality_stockyards_checklists', 'scheduling_manchange'];
      case PermissionFunctions.QUALITY_EASTON_CHECKLISTS:
        return ['quality_easton_checklists', 'scheduling_manchange'];
      case PermissionFunctions.BAGGING:
        return ['bagging', 'scheduling_manchange'];
      case PermissionFunctions.EASTON_SCHEDULING:
        return ['easton_scheduling'];
      case PermissionFunctions.MIXING_SUPERVISOR:
        return ['mixing_supervisor'];
    }

    return [];
  };
}

export default Routes;
